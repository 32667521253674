import * as React from 'react';
import 'materialize-css/dist/css/materialize.min.css';

export const Contact = () => {
  return (
    <>
        <p> hey it's me, oswald! </p>
    </>
  );
}

export default Contact;